<template>
	<div>
		<search-list-table
			ref="searchListTable"
			:columns="columns"
			:formSearchList="formSearchList"
			:initDataSource="initDataSource"
			:upfrontFeesRowSelection="upfrontFeesRowSelection"
		>
			<template slot="btn" v-if="isRecorded === '1'">
				<a-button type="primary" @click="exportSheet">
					{{ $t('page.download') }}
				</a-button>
			</template>
			<template slot="toggleTable">
				<a-tabs :activeKey="isRecorded" @change="handleTabChange">
					<a-tab-pane key="1" :tab="$t('table.finance.repayment.repayRecored')"> </a-tab-pane>
					<a-tab-pane key="0" :tab="$t('table.finance.repayment.repayToBeRecored')"> </a-tab-pane>
					<template slot="tabBarExtraContent" v-if="isRecorded === '0'">
						<div v-if="upfrontFeesRowSelection === null" style="line-height: 42px">
							<span>When it is Upfront Fee , you need to </span> <a-button @click="selectRow"> Select </a-button>
						</div>
						<div v-else style="line-height: 42px">
							<a-button @click="upfrontFees"> Upfront Fees </a-button> <a-button @click="upfrontFeesRowSelection = null"> Cancel </a-button>
						</div>
					</template>
				</a-tabs>
			</template>
			<template slot="scopedSlots" slot-scope="{ columnData }">
				<a @click="SowReason(columnData)">
					<a-statistic
						:precision="2"
						:value="
							columnData.discountInterestAmount +
							columnData.discountOtherAmount +
							columnData.discountPenaltyAmount +
							columnData.discountMaintenanceAmount +
							columnData.discountTrackingAmount
						"
					/>
				</a>
			</template>
			<template slot="action" slot-scope="{ columnData }">
				<a-button v-if="columnData.status === 3" type="primary" @click="uploadRepayment(columnData)">
					{{ $t(`table.finance.cloum.statusMap.${columnData.status}`) }}
				</a-button>
				<span v-else-if="isRecorded === '0'">
					<a-button type="primary" @click="uploadRepayment(columnData)">
						{{ $t(`table.finance.cloum.statusMap.4`) }}
					</a-button>
					<a-popconfirm title="Are you sure to delete？" ok-text="Yes" cancel-text="No" @confirm="apiMpesaOfflineDelete(columnData.id)">
						<a-button type="primary" style="margin-left: 10px">{{ $t(`operate.del`) }}</a-button>
					</a-popconfirm>
				</span>
				<span v-else>{{ $t(`table.finance.cloum.statusMap.${columnData.status}`) }}</span>
			</template>
		</search-list-table>
		<discount-modal ref="discountModal" :columnData="discountModalColumnData"></discount-modal>
		<upload-repayment ref="uploadRepayment" @repaymentsInitTable="repaymentsInitTable"></upload-repayment>
	</div>
</template>
<script>
import UploadRepayment from '@/views/businessComponents/UploadRepayment.vue'
import DiscountModal from '@/views/businessComponents/DiscountModal.vue'
import { apiRepayList, apiMpesaOfflineDelete, apiUpfrontSet, apiRepayListData } from '@/api/loan'
import { apiBranchList } from '@/api/branch'
import moment from 'moment'
export default {
	data() {
		return {
			branchOfficeList: [],
			repayModalShow: false,
			timer: null,
			isRecorded: '1',
			upfrontFeesRowSelection: null,
			upfrontFeesSelectRow: [],
			discountModalColumnData: {}
		}
	},
	components: { UploadRepayment, DiscountModal },
	computed: {
		formSearchList() {
			return [
				// {
				// 	label: 'table.applyInfo.cloums.caseId',
				// 	type: 'input',
				// 	validateField: ['caseId']
				// },
				{
					label: 'table.finance.cloum.payID',
					type: 'input',
					validateField: ['paymentId']
				},
				{
					label: 'table.applyInfo.cloums.userName',
					type: 'input',
					validateField: ['name']
				},
				{
					label: 'table.applyInfo.cloums.mobile',
					type: 'input',
					validateField: ['mobile']
				},
				{
					label: 'table.finance.cloum.arrivalTime',
					type: 'range-picker',
					validateField: ['arrivalTime', { initialValue: [] }],
					paramsName: ['startTime', 'endTime']
				},
				{
					label: 'table.finance.cloum.payType',
					type: 'select',
					validateField: ['transactionType'],
					selectOption: [0, 1, 2, 3, 4],
					prefix: 'table.finance.repayment.payTypeMap'
				},
				{
					label: 'table.finance.cloum.status',
					type: 'select',
					validateField: ['status'],
					selectOption: [0, 1, 2, 3],
					prefix: 'table.finance.cloum.statusMap'
				},
				{
					label: 'table.finance.cloum.licensePlateNumber',
					type: 'input',
					validateField: ['registrationNumber']
				},
				{
					label: 'table.applyInfo.loanApplyLabel.branchOffice',
					type: 'select',
					validateField: ['officeId'],
					selectOption: this.branchOfficeList,
					dataPreType: {
						value: 'id',
						text: 'storeName'
					}
				}
			]
		},
		columns() {
			const columnsList =
				this.isRecorded === '1'
					? [
							{
								// 案件ID
								title: 'No.',
								scopedSlots: { customRender: 'serial_number' },
								width: 80,
								align: 'center',
								fixed: 'left'
							},
							{
								// 支付ID
								title: this.$t('table.finance.cloum.payID'),
								dataIndex: 'paymentId',
								align: 'center',
								fixed: 'left'
							},
							{
								// 案件ID
								title: this.$t('table.applyInfo.cloums.caseId'),
								dataIndex: 'loanId',
								align: 'center',
								customRender: (v, o) => {
									return o.casePrefix + '-' + o.caseId
								}
							},
							{
								// 用户名
								title: this.$t('table.applyInfo.cloums.userName'),
								dataIndex: 'fullName',
								align: 'center'
							},
							{
								// 车牌号
								title: this.$t('table.CustomerManagement.fromdata.licensePlateNumber'),
								dataIndex: 'registrationNumbers',
								align: 'center',
								customRender: (v) => (v ? v.join('/') : '-')
							},
							{
								// 手机号
								title: this.$t('table.finance.cloum.mobile'),
								dataIndex: 'mobiles',
								align: 'center',
								customRender: (v) => {
									let vhtml = v
										? v.map((item) => {
												return <p>{item}</p>
										  })
										: ''
									return vhtml
								}
							},
							// {
							// 	// 支付金额
							// 	title: this.$t('table.finance.cloum.payAmount'),
							// 	dataIndex: 'paymentAmount',
							// 	align: 'center',
							// 	scopedSlots: { customRender: 'money_format' }
							// },
							{
								// 到账时间
								title: this.$t('table.finance.cloum.arrivalTime'),
								dataIndex: 'transactionDate',
								align: 'center'
							},
							{
								// 支付凭证
								title: this.$t('table.finance.cloum.payCode'),
								align: 'center',
								customRender: (o) => {
									return o.transactionTypeEnum.value === 4 ? (
										'-'
									) : (
										<a onclick={() => this.GoPayCode(o.transactionFiles)}>{this.$t('page.checkDetail')}</a>
									)
								}
							},
							{
								// 还款类型
								title: this.$t('table.finance.cloum.payType'),
								scopedSlots: { customRender: 'payType' },
								align: 'center',
								customRender: (o) => {
									return (
										<div>
											{this.$t(`table.finance.repayment.payTypeMap.${o.transactionTypeEnum.value}`)}
											<br />
											{o.carPlateNo ? `(REG: ${o.carPlateNo})` : ''}
										</div>
									)
								}
							},
							{
								// 到账金额
								title: this.$t('table.finance.cloum.amountReceived'),
								dataIndex: 'amount',
								align: 'center',
								scopedSlots: { customRender: 'money_format' }
							},
							{
								// 额外平账金额
								title: this.$t('table.finance.cloum.balance'),
								align: 'center',
								scopedSlots: { customRender: 'scopedSlots' }
							},
							{
								// 是否结单
								title: this.$t('table.finance.cloum.check'),
								align: 'center',
								customRender: (o) => {
									return o.needClosedLoan ? this.$t('page.yes') : this.$t('page.no')
								}
							},
							{
								// 录入人ID
								title: this.$t('table.finance.cloum.entryName'),
								dataIndex: 'userName',
								align: 'center',
								customRender: (v, o) => (o.transactionTypeEnum.value === 4 ? '-' : v)
							},
							{
								// 状态
								title: this.$t('table.finance.cloum.status'),
								align: 'center',
								fixed: 'right',
								scopedSlots: { customRender: 'action' }
							}
					  ]
					: [
							{
								// 案件ID
								title: 'No.',
								scopedSlots: { customRender: 'serial_number' },
								align: 'center',
								width: 80,
								fixed: 'left'
							},
							{
								// 支付ID
								title: this.$t('table.finance.cloum.receiptNo'),
								dataIndex: 'paymentId',
								align: 'center',
								width: 180,
								fixed: 'left'
							},
							{
								// 入账时间
								title: this.$t('table.finance.cloum.completionTime'),
								dataIndex: 'transactionDate',
								align: 'center'
							},
							{
								// 入账金额
								title: this.$t('table.finance.cloum.TransAmount'),
								dataIndex: 'amount',
								align: 'center',
								scopedSlots: { customRender: 'money_format' }
							},
							{
								// 还款类型
								title: this.$t('table.finance.cloum.payType'),
								align: 'center',
								dataIndex: 'transactionType',
								customRender: (v, o) => {
									return this.$t(`table.finance.repayment.payTypeMap.${o.transactionType.value}`)
								}
							},
							{
								// 手机号码账号
								title: this.$t('table.finance.cloum.MSISDN'),
								align: 'center',
								dataIndex: 'msisdn'
							},
							{
								// 用户名
								title: this.$t('table.applyInfo.cloums.userName'),
								width: 250,
								dataIndex: 'fullName',
								align: 'center'
							},
							{
								// 车牌
								title: this.$t('table.finance.cloum.billRefNo'),
								width: 250,
								dataIndex: 'billRefNumber',
								align: 'center'
							},
							{
								// 状态
								title: this.$t('table.finance.cloum.status'),
								align: 'center',
								fixed: 'right',
								width: 250,
								scopedSlots: { customRender: 'action' }
							}
					  ]
			return columnsList
		}
	},
	methods: {
		uploadRepayment(columnData) {
			this.$refs.uploadRepayment.uploadRepayment(
				null,
				{
					paymentId: columnData.paymentId,
					transactionDate: columnData.transactionDate,
					amount: columnData.amount,
					recordId: columnData.id,
					transactionTypeEnum: columnData.transactionType.value
				},
				'mpesaRepayment'
			)
		},
		repaymentsInitTable(pageNum) {
			this.$refs.searchListTable.initTable(pageNum)
		},
		selectRow() {
			this.upfrontFeesRowSelection = {
				fixed: true,
				columnWidth: 50,
				onChange: (k, r) => {
					this.upfrontFeesSelectRow = r
				}
			}
		},
		upfrontFees() {
			const _this = this
			this.$confirm({
				content: 'Please check again to make sure. You cannot undo  this operation.',
				cancelText: 'Confirm',
				okText: 'Cancel',
				onOk: async () => {
					_this.upfrontFeesRowSelection = null
				},
				onCancel() {
					if (_this.upfrontFeesSelectRow.length === 0) {
						_this.$message.error('Select Empty')
						return false
					}
					const params = _this.upfrontFeesSelectRow.map((item) => {
						return item.id
					})
					apiUpfrontSet(params).then((res) => {
						_this.$message.success('Success')
						_this.upfrontFeesRowSelection = null
						_this.pagination.current = 1
						_this.$refs.searchListTable.initTable(1)
					})
				}
			})
		},
		initDataSource(initParams) {
			return apiRepayList({ ...initParams, isRecorded: Number(this.isRecorded) })
		},
		moment: moment,
		handleTabChange(activeKey) {
			this.isRecorded = activeKey
			this.$refs.searchListTable.initTable(1)
		},
		// 查看原因
		SowReason(columnData) {
			this.$refs.discountModal.updateModalShow(true)
			this.discountModalColumnData = columnData
		},
		GoPayCode(url) {
			if (!this.$Util.isEmpty(url)) {
				let linkurl = this.$Util.getObsImageUrl(this.$store.state.pageState.authObj, url)
				window.open(linkurl, '_blank')
			}
		},
		apiMpesaOfflineDelete(params) {
			apiMpesaOfflineDelete([params]).then((res) => {
				this.$message.success('Successfully Deleted')
				this.$refs.searchListTable.initTable(1)
			})
		},
		exportSheet() {
			const searchForm = { ...this.$refs.searchListTable.searchFormTable.getFieldsValue() }
			searchForm.startTime = searchForm.arrivalTime[0] && searchForm.arrivalTime[0].format('YYYY-MM-DD') + ' 00:00:00'
			searchForm.endTime = searchForm.arrivalTime[1] && searchForm.arrivalTime[1].format('YYYY-MM-DD') + ' 23:59:59'
			delete searchForm.arrivalTime
			apiRepayListData(searchForm).then((res) => {
				let str = `Reference ID/Receipt No.,Case ID,Client Name,Registration No.,Mobile No.,Execution Time,Repayment Type,Amount Received,Discount on interest/ other charges,Case Closed,Entry,Status`
				res.forEach((item) => {
					str += `\r\n${item.paymentId},${item.casePrefix}-${item.caseId},${item.fullName},${
						item.registrationNumbers ? item.registrationNumbers.join('/') : '-'
					},${item.mobiles ? item.mobiles.join('/') : '-'},${item.transactionDate},${this.$t(
						`table.finance.repayment.payTypeMap.${item.transactionTypeEnum.value}`
					)} ${item.carPlateNo ? '(REG: ' + item.carPlateNo + ')' : ''},${item.amount},${
						item.discountInterestAmount +
						item.discountOtherAmount +
						item.discountPenaltyAmount +
						item.discountTrackingAmount +
						item.discountMaintenanceAmount
					},${item.needClosedLoan ? this.$t('page.yes') : this.$t('page.no')},${item.transactionTypeEnum.value === 4 ? '-' : item.userName},${this.$t(
						`table.finance.cloum.statusMap.${item.status}`
					)}`
				})
				//encodeURIComponent解决中文乱码
				let uri = 'data:text/csv;charset=utf-8,\ufeff' + encodeURIComponent(str)
				let link = document.createElement('a')
				link.href = uri
				link.download = `Repayment Recorded.csv`
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
			})
		}
	},
	beforeDestroy() {
		clearTimeout(this.timer)
	},
	destroyed() {
		clearTimeout(this.timer)
	},
	created() {
		this.$nextTick(() => {
			apiBranchList().then((res) => {
				this.branchOfficeList = res
			})
		})
	}
}
</script>
<style scoped lang="less">
.ant-input,
.ant-input-number,
.ant-select {
	width: 200px;
}
.ant-form-item {
	margin-bottom: 10px;
}
.mainContent {
	.ant-tabs-extra-content {
		line-height: 42px;
	}
}

.file {
	left: 0;
	top: 0;
	width: 100%;
	bottom: 0;
	position: absolute;
	opacity: 0;
	cursor: pointer;
}
</style>
